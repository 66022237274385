body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  background-color: rgba(198, 182, 192, .05);
}

* {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "Bree Serif";
  src: url('./assets/fonts/BreeSerif-Regular.ttf');
}

@font-face {
  font-family: "Nunito";
  src: url('./assets/fonts/Nunito.ttf');
}

.title {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: #616e5f;
  font-weight: 600;
  margin: 0;
  padding: 0;
  max-width: 80vw;
  word-break: break-word;
}

.context {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  color: #4a564a;
  font-weight: 500;
  margin: 0;
  padding: 0;
  max-width: 80vw;
  text-align: center;
}

@media(max-width: 800px) {
  .inner-pager {
    padding-left: 0;
  }
}

html {
}

div#inner-pager {
  padding-bottom: 5rem;
}

p {
  text-rendering: optimizeLegibility;
}

div#pageContent {
  overflow-x: hidden;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
